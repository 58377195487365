import React, { useState, useEffect } from 'react';
import style from './style.css';
import { ReviewMetrics } from '../../snippet/metrics';

const SearchBar = ({
    onSearch, handleClearSearch, isSearchInitiated, productReviews, searchResultsCount, searchTerm,
}) => {
    const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm);

    useEffect(() => {
        setLocalSearchTerm(searchTerm);
    }, [searchTerm]);

    const handleInputChange = (e) => {
        const newSearchTerm = e.target.value;
        setLocalSearchTerm(newSearchTerm);
        if (newSearchTerm === '') {
            handleClearSearch();
        }
    };

    const handleSearchClick = () => {
        onSearch(localSearchTerm);
    };
    const iconClass = localSearchTerm ? style.searchIconActive : style.RdSearchReviewsIconButton;
    const { reviewsCount } = ReviewMetrics(productReviews);
    const displaySearchCount = searchResultsCount;
    const displayReviewCount = reviewsCount;
    const searchText = `${displaySearchCount} results`;
    const displayText = !isSearchInitiated ? `Reviewed by ${displayReviewCount} customers` : '';

    return (
        <div className={style.RdReviewHeaderContents}>
            <h1 className={style.RdReviewTotal}>
                {isSearchInitiated && localSearchTerm && (
                    `${searchText}`
                )}
                {displayText}
            </h1>
            <section className={style.RdSearchContainer}>
                <div className={style.RdSearchReviewsInput}>
                    <input
                        id="input-search"
                        type="search"
                        aria-label="Search Reviews"
                        placeholder="Enter Search Terms"
                        value={localSearchTerm}
                        onChange={handleInputChange}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearchClick();
                            }
                        }}
                        className={style.searchInput}
                    />
                    <button
                        type="button"
                        aria-label="Search Reviews"
                        className={iconClass}
                        onClick={handleSearchClick}
                    >
                        <span className={style.searchIcon}>
                            <svg viewBox="-323.088 -91.768 148.57 148.57" focusable="false">
                                <path
                                    fill="#fff"
                                    d="M -220.233 -28.913 C -220.233 -39.925 -224.147 -49.343 -231.971 -57.171 C -239.799 -64.999 -249.221 -68.913 -260.233 -68.913 C -271.243 -68.913 -280.663 -64.999 -288.49 -57.171 C -296.32 -49.343 -300.233 -39.925 -300.233 -28.913 C -300.233 -17.901 -296.319 -8.479 -288.491 -0.655 C -280.661 7.173 -271.244 11.087 -260.233 11.087 C -249.221 11.087 -239.799 7.173 -231.971 -0.655 C -224.147 -8.479 -220.233 -17.901 -220.233 -28.913 Z M -174.518 45.372 C -174.518 48.47 -175.648 51.146 -177.908 53.407 C -180.17 55.669 -182.85 56.802 -185.948 56.802 C -189.158 56.802 -191.838 55.669 -193.983 53.407 L -224.608 22.872 C -235.26 30.255 -247.135 33.947 -260.233 33.947 C -268.743 33.947 -276.885 32.294 -284.651 28.989 C -292.421 25.685 -299.116 21.219 -304.741 15.595 C -310.366 9.97 -314.831 3.275 -318.135 -4.491 C -321.435 -12.261 -323.088 -20.401 -323.088 -28.913 C -323.088 -37.425 -321.436 -45.565 -318.135 -53.331 C -314.83 -61.101 -310.365 -67.796 -304.74 -73.421 C -299.115 -79.046 -292.42 -83.511 -284.65 -86.815 C -276.885 -90.115 -268.744 -91.768 -260.233 -91.768 C -251.721 -91.768 -243.58 -90.116 -235.811 -86.815 C -228.045 -83.51 -221.346 -79.045 -215.721 -73.421 C -210.096 -67.796 -205.635 -61.101 -202.331 -53.331 C -199.026 -45.565 -197.373 -37.425 -197.373 -28.913 C -197.373 -15.815 -201.065 -3.94 -208.448 6.712 L -177.823 37.337 C -175.619 39.54 -174.518 42.22 -174.518 45.372 Z M -174.518 45.372"
                                    transform="matrix(1, 0, 0, 1, 2.842170943040401e-14, 0)"
                                />
                            </svg>
                        </span>
                    </button>
                </div>
            </section>
        </div>
    );
};

export default SearchBar;
