import { connect } from '@silkpwa/redux';
import { IBreadcrumb } from '@silkpwa/module/react-component/breadcrumbs/breadcrumbs';

interface IProductAttribute {
    code: string;
    value: string;
    'backend_value': string;
    label: string;
}

interface ISelection {
    'backorder_message': string;
    'can_change_qty': boolean;
    color: string;
    'color_swatch_image': string;
    'default_qty': number;
    'is_default': boolean;
    'is_item_saleable': boolean;
    'is_saleable': boolean;
    'original_price': string;
    'price': string;
    'product_alert_message': string;
    'product_id': number;
    'selection_id': number;
    'selection_title': string;
    size: string;
    sku: string;
    'stock_message': string;
    'tier_price': string[];
    'type_id': string;
}

interface IBundledProduct {
    id: number;
    required: boolean;
    selections: ISelection[];
    'show_single': boolean;
    title: string;
    type: string;
}

interface IProductImage {
    full: string;
    image: string;
    thumb: string;
    type: string;
}

interface IIndexSelection {
    attribute: number;
    value: number;
}

interface IProductIndex {
    productId: number;
    selections: IIndexSelection[];
}

interface IProductOption {
    id: number;
    options: IProductOption[];
    type: string;
    label?: string;
    value?: string;
}

interface IProductRewardsData {
    'exclude_text': string;
    message: string;
    money: boolean;
    'pdp_text': string;
    'plp_text': string;
    'point_display': boolean;
    points: string;
    'product_id': number;
    'visible_on_pdp': boolean;
    'visible_on_plp': boolean;
}

interface IProductStockData {
    'backorders_estimated_label': string;
    'estimated_shipping': string;
    'is_product_alert': boolean;
    'product_alert_message': string;
    'stock_label': string;
    'swatch_stock_label': string;
    'stock_estimated_label': string;
}

export interface IProductCustomAttribute {
    'attribute_code': string;
    value: any; // TODO: type
}

export interface IProductCategoryLinks {
    position: number;
    'category_id': string;
}

export interface IProductExtensionAttribute {
    'website_ids': number[];
    'category_links': IProductCategoryLinks[];
    /* needs to add other field */
}

export interface IOriginalProductProduct {
    'custom_attributes': IProductCustomAttribute[];
    'extention_attribute': IProductExtensionAttribute[];
    /* needs to add other field */
}

export interface IProductOriginalProduct extends IProduct {
    product: IOriginalProductProduct;
}

export interface IProduct {
    arePricesInvalidated: boolean;
    attributes: IProductAttribute[];
    breadcrumbs: IBreadcrumb[];
    bundledProducts: IBundledProduct[];
    canonical: string;
    colorIndex: string[];
    customOptions: string[];
    description: string;
    hasDefaultEmbroideryAndLocked: boolean;
    id: number;
    images: IProductImage[];
    inStock: boolean;
    index: string[] | IProductIndex[];
    isEnabled: boolean;
    isQtyIncrementsEnable: boolean;
    loading: boolean;
    maxPrice: string;
    maxQuantity: number;
    'meta_description': string;
    'meta_keyword': string;
    'meta_title': string;
    minPrice: string;
    minQuantity: number;
    name: string;
    options: IProductOption[];
    originalPrice: string;
    originalProduct: IProductOriginalProduct;
    pdpMessage: string;
    pdpUnitMessage: string;
    price: string;
    qtyIncrements: number;
    quantity: number;
    rewardsData: IProductRewardsData;
    richSnippet: string;
    robots: string;
    shortDescription: string;
    sku: string;
    stockData: IProductStockData;
    temporaryRedirectUrl: string;
    tierPrices: string[]; // todo
    type: string;
    unformatted: IProduct;
    url: string;
}

const Product = ({ product, fallback, children }) => {
    if (product.id === -1) return fallback || null;
    return children(product);
};

export const ConnectProduct = connect({
    using: ['ecommerceProduct'],
    mapStateToProps: product => state => ({
        product: product.selectors.getCurrentProduct(state),
    }),
})(Product);
