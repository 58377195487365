import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { RewardsItem } from './rewards-item';
import styles from './style.css';

interface AccordionItemProps {
    img?: string;
    text: string;
    type: string;
    isActive: boolean;
    toggle: () => void;
}
export const RewardsAccordionItem: React.FC<AccordionItemProps> = ({
    img,
    text,
    type,
    isActive,
    toggle,
    children,
}) => {
    const t = usePhraseTranslater();
    return (
        <li className={styles.accordionItem}>
            {type === 'bronze' && (
                <RewardsItem
                    img={img}
                    text={text}
                    isActive={isActive}
                    toggle={toggle}
                    tierText={t('Free Tier')}
                    className={styles.accordionHeaderBronze}
                />
            )}
            {type === 'silver' && (
                <RewardsItem
                    img={img}
                    text={text}
                    isActive={isActive}
                    toggle={toggle}
                    tierText={t('Free Tier')}
                    className={styles.accordionHeaderSilver}
                />
            )}
            {type === 'gold' && (
                <RewardsItem
                    img={img}
                    text={text}
                    isActive={isActive}
                    toggle={toggle}
                    tierText={t('Free Tier')}
                    className={styles.accordionHeaderGold}
                />
            )}
            {type === 'ruby' && (
                <RewardsItem
                    img={img}
                    text={text}
                    isActive={isActive}
                    toggle={toggle}
                    tierText={t('Executive Tier')}
                    className={styles.accordionHeaderRuby}
                />
            )}
            {type === 'diamond' && (
                <RewardsItem
                    img={img}
                    text={text}
                    isActive={isActive}
                    toggle={toggle}
                    tierText={t('Executive Tier')}
                    className={styles.accordionHeaderDiamond}
                />
            )}
            {isActive && (
                <div className={styles.content}>
                    {children}
                </div>
            )}
        </li>
    );
};
