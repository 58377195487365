import React, { useState, useMemo } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { connectProductReviews } from '@silkpwa/module/react-component/connect-product-reviews';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { injectProps } from '@silkpwa/redux';
import { DropDown } from 'ui/component/drop-down';
import SearchBar from 'ui/component/native-reviews/display/search/search';
import { Snapshot } from 'ui/component/native-reviews/display/snapshot/snapshot';
import { StarRating } from 'ui/component/native-reviews/display/display/rating';
import { TimeDifference } from 'ui/component/native-reviews/display/display/time-diff';
import { MoreDetailsButton } from 'ui/component/native-reviews/display/display/more-details-btn';
import Verified from 'ui/component/native-reviews/display/display/verified';
import { ReviewMedias } from 'ui/component/native-reviews/display/display/review-images';
import { Section } from 'ui/component/native-reviews/components/section';
import { SectionTitle } from 'ui/component/native-reviews/components/section-title';
import { Footer } from 'ui/component/native-reviews/components/footer';
import { ReviewForm } from 'ui/component/native-reviews/review-form';
import { ScrollToElementBtn } from 'ui/component/native-reviews/hooks/scroll-to-element-btn';
import { ReviewHelpfulness } from 'ui/component/native-reviews/display//display/review-helpfulness';
import screenSwitch from 'ui/styles/screen-switch.css';
import { ReviewMediasSlider } from 'ui/component/native-reviews/display/display/review-medias-slider';
import { useQuery } from '@apollo/client';
import { GET_REVIEW_MEDIA_SLIDER } from 'graphql/review/mutations/review-slider';
import { ShowLoader } from 'ui/component/show-loader';
import style from 'ui/component/native-reviews/display/style.css';

const injectWindow = injectProps('window');

const isReviews = window => (
    window.location.hash &&
    window.location.hash.indexOf('#write-review') > -1
);

export const useReviewMediaSlider = (productId: number) => useQuery(GET_REVIEW_MEDIA_SLIDER, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: { productId },
});

export const ReviewDisplayImpl = ({
    productId,
    productReviews,
    state,
    handleOptionChange,
    resetReviewAfterVote,
    fetchProductReviews,
}) => {
    const t = usePhraseTranslater();
    const { data, loading } = useReviewMediaSlider(productId);

    const reviewMediaSliderData = data?.reviewMediaSlider || [];

    const recommendText = (value) => {
        if (value === 1) {
            return t('Yes, I would recommend to a friend');
        }
        if (value === 2) {
            return t('No, I would not recommend to a friend');
        }
        return 'Reviewer did not respond';
    };

    const [filter, setFilter] = useState(null);
    const applyFilter = (star) => {
        setFilter(star);
    };
    const clearFilter = () => {
        setFilter(null);
    };
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearchInitiated, setIsSearchInitiated] = useState(false);
    const onSearch = (term) => {
        setSearchTerm(term);
        setIsSearchInitiated(true);
    };

    const handleClearSearchTerm = () => {
        setSearchTerm('');
        setIsSearchInitiated(false);
    };
    const maxReviewsDisplayed = 51;

    const filteredAndSortedReviews = useMemo(() => {
        const filtered = productReviews.filter((review) => {
            const matchesRating = !filter || (Array.isArray(review.rating_votes) &&
                review.rating_votes.length > 0 &&
                review.rating_votes[0].value === filter);

            const matchesSearch = !searchTerm || review.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                review.detail.toLowerCase().includes(searchTerm.toLowerCase());

            return matchesRating && matchesSearch;
        });

        return filtered.sort((a, b) => {
            switch (state.sort) {
                case 'date-desc':
                    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
                case 'date-asc':
                    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
                case 'rating-desc':
                    return parseFloat(b.rating_votes[0].value) - parseFloat(a.rating_votes[0].value);
                case 'rating-asc':
                    return parseFloat(a.rating_votes[0].value) - parseFloat(b.rating_votes[0].value);
                default:
                    return 0;
            }
        });
    }, [productReviews, filter, searchTerm, state.sort]);

    const searchResultsCount = filteredAndSortedReviews.length;

    function escapeRegExp(string: string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    }

    let uniqueCounter = 0;

    function boldSearchResults(text, searchTerm) {
        if (!searchTerm) return text;
        uniqueCounter += 1;
        const currentCount = uniqueCounter;

        const parts = text.split(new RegExp(`(${escapeRegExp(searchTerm)})`, 'gi'));
        let localCounter = 0;
        return parts.map((part) => {
            localCounter += 1;
            const partKey = `key-${currentCount}-${localCounter}`;
            return part.toLowerCase() === searchTerm.toLowerCase() ? (
                <strong
                    className="searchTermFound"
                    key={partKey}
                >
                    {part}
                </strong>
            ) : part;
        });
    }

    const renderFilterMessage = () => {
        const hasFilter = !!filter;
        const hasSearchTerm = !!searchTerm;

        const handleClearStarFilter = () => setFilter(null);

        return (
            <>
                {hasFilter && (
                    <div className={style.filterNotice}>
                        <h1 className={style.filterHeadline}>
                            Filters
                            Applied:
                        </h1>
                        {' '}
                        <div className={style.filterLabel}>
                            <button
                                onClick={handleClearStarFilter}
                                className={style.clearFilterButton}
                                aria-label="Clear filter for  star rating"
                                type="button"
                            >
                                <span className={style.filterClose}>
                                    <svg
                                        viewBox="9.5 9.5 21 21"
                                        focusable="false"
                                    >
                                        <g
                                            className={style.filterCloseGroup}
                                        >
                                            <circle
                                                className={style.closeCircle}
                                                cx="20"
                                                cy="20"
                                                r="10"
                                                stroke="#333"
                                                strokeWidth="1"
                                                fill="none"
                                            />
                                            <line
                                                className={style.closeLine}
                                                x1="15"
                                                y1="15"
                                                x2="25"
                                                y2="25"
                                                stroke="#333"
                                                strokeWidth="2"
                                            />
                                            <line
                                                className={style.closeLine}
                                                x1="25"
                                                y1="15"
                                                x2="15"
                                                y2="25"
                                                stroke="#333"
                                                strokeWidth="2"
                                            />
                                        </g>
                                    </svg>
                                </span>
                                <span className={style.filterLabelStars}>
                                    {filter}
                                    {' '}
                                    Stars
                                </span>
                            </button>
                        </div>
                    </div>
                )}
                {hasSearchTerm && (
                    <div className={style.filterLabel}>
                        <button
                            onClick={handleClearSearchTerm}
                            className={style.clearFilterButton}
                            aria-label="Clear filter for search term"
                            type="button"
                        >
                            <span className={style.filterClose}>
                                <svg viewBox="9.5 9.5 21 21" focusable="false">
                                    <g
                                        className={style.filterCloseGroup}
                                    >
                                        <circle
                                            className={style.closeCircle}
                                            cx="20"
                                            cy="20"
                                            r="10"
                                            stroke="#333"
                                            strokeWidth="1"
                                            fill="none"
                                        />
                                        <line
                                            className={style.closeLine}
                                            x1="15"
                                            y1="15"
                                            x2="25"
                                            y2="25"
                                            stroke="#333"
                                            strokeWidth="2"
                                        />
                                        <line
                                            className={style.closeLine}
                                            x1="25"
                                            y1="15"
                                            x2="15"
                                            y2="25"
                                            stroke="#333"
                                            strokeWidth="2"
                                        />
                                    </g>
                                </svg>
                            </span>
                            <span
                                className={style.filterLabelStars}
                            >
                                {searchTerm}
                            </span>
                        </button>
                    </div>
                )}
            </>
        );
    };

    const createOptions = () => [
        {
            id: 'date-desc',
            label: t('Most Recent'),
        },
        {
            id: 'rating-asc',
            label: t('Lowest Rated'),
        },
        {
            id: 'rating-desc',
            label: t('Highest Rated'),
        },
        {
            id: 'date-asc',
            label: t('Oldest'),
        },
    ];

    return (
        <>
            {!isReviews(window) && (
                <Section
                    id="review-display"
                    className={classes(style.reviewDisplay, style.rdDisplayDesktop)}
                >
                    {(productReviews.length > 0) && (
                        <SectionTitle
                            id="rd-main-header"
                            className={classes(
                                style.rdMainHeader,
                                style.rdMainHeaderWithFilters,
                            )}
                        >
                            {loading && (
                                <div className={style.sliderLoader}>
                                    <ShowLoader message="" />
                                </div>
                            )}
                            {!loading && (
                            <div className={style.rdReviewHeaderSorts}>
                                <div className={screenSwitch.showOnDesktop}>
                                    <ReviewMediasSlider
                                        reviewMediaSliderData={reviewMediaSliderData}
                                        itemsClassName={style.itemsClass}
                                        arrowClassName={style.arrowClass}
                                        show={6}
                                    />
                                </div>
                                <div className={`${screenSwitch.showOnLargeMobile} ${screenSwitch.showOnMediumMobile}`}>
                                    <ReviewMediasSlider
                                        reviewMediaSliderData={reviewMediaSliderData}
                                        itemsClassName={style.itemsClass}
                                        arrowClassName={style.arrowClass}
                                        show={3}
                                    />
                                </div>
                                <div className={screenSwitch.showOnSmallMobile}>
                                    <ReviewMediasSlider
                                        reviewMediaSliderData={reviewMediaSliderData}
                                        itemsClassName={style.itemsClass}
                                        arrowClassName={style.arrowClass}
                                        show={2}
                                    />
                                </div>
                                <div className={screenSwitch.showOnVerySmallMobile}>
                                    <ReviewMediasSlider
                                        reviewMediaSliderData={reviewMediaSliderData}
                                        itemsClassName={style.itemsClass}
                                        arrowClassName={style.arrowClass}
                                        show={1}
                                    />
                                </div>

                                <div className={style.rdSortGroup}>
                                    <Snapshot
                                        productReviews={productReviews}
                                        applyFilter={applyFilter}
                                        clearFilter={clearFilter}
                                        filter={filter}
                                    />
                                </div>
                            </div>
                            )}
                            <div className={style.rdReviewHeaderSorts}>
                                <div className={style.rdSortGroup}>
                                    <SearchBar
                                        onSearch={onSearch}
                                        handleClearSearch={handleClearSearchTerm}
                                        isSearchInitiated={isSearchInitiated}
                                        productReviews={productReviews}
                                        searchResultsCount={searchResultsCount}
                                        searchTerm={searchTerm}
                                    />
                                </div>
                            </div>
                            <div
                                className={style.rdReviewHeaderSorts}
                                id={style.rdDropdown}
                            >
                                <div className={style.rdSortGroup}>
                                    <DropDown
                                        label={t('Sort by')}
                                        options={createOptions()}
                                        selected={state.sort}
                                        handleSelect={handleOptionChange}
                                    />
                                </div>
                            </div>
                            <div
                                className={style.filterSection}
                            >
                                {renderFilterMessage()}
                            </div>
                        </SectionTitle>
                    )}
                    {filteredAndSortedReviews.slice(0, maxReviewsDisplayed).map((review) => {
                        // prevent bad data or empty rating from crashing
                        const ratingValue = Array.isArray(review.rating_votes) && review.rating_votes.length > 0
                            ? review.rating_votes[0].value
                            : null;
                        return (
                            <div
                                key={review.id}
                                className={classes(style.review, `${style.review}-${review.id}`)}
                            >
                                <SectionTitle
                                    className={classes(style.rdHeader, style.rdContentBlock)}
                                    id={style.starsHeadline}
                                >
                                    <div className={style.rdStarRating}>
                                        <div
                                            className={classes(style.snippetStars, style.snippetStarsPng)}
                                            role="img"
                                            aria-label={ratingValue !== null ? `Rated ${ratingValue} out of 5 stars` : 'Rating not available'}
                                        >
                                            <div
                                                aria-hidden="true"
                                                className={style.ratingStars}
                                            >
                                                <StarRating
                                                    rating={ratingValue !== null ? ratingValue : 0}
                                                />
                                            </div>
                                            <div
                                                aria-hidden="true"
                                                className={style.snippetRatingDecimal}
                                            >
                                                {ratingValue !== null ? ratingValue : 'N/A'}
                                            </div>
                                        </div>
                                    </div>
                                    <h2
                                        className={style.rdReviewHeadline}
                                        data-tab-index="-1"
                                        id={`rd-review-headline-${productId}`}
                                    >
                                        {review.title}
                                    </h2>
                                </SectionTitle>
                                <Section className={classes(style.rdDescription, style.rdContentBlock)}>
                                    <p className={style.rdDescriptionText}>
                                        {boldSearchResults(review.detail, searchTerm)}
                                    </p>
                                    <div
                                        className={classes(style.rdSideContentBlock, style.rdRight)}
                                    >
                                        <div
                                            className={classes(style.rdReviewerDetails, style.rdInnerSideContentBlock)}
                                        >
                                            <p className={classes(style.rdDetails, style.rdAuthorSubmissionDate)}>
                                                <span className={style.rdBold}>
                                                    Submitted
                                                </span>
                                                <span
                                                    data-datetime={review.created_at}
                                                >
                                                    <TimeDifference
                                                        createdAt={review.created_at}
                                                    />
                                                </span>
                                            </p>
                                            <p className={classes(style.rdDetails, style.rdAuthorNickname)}>
                                                <span className={style.rdBold}>
                                                    By&nbsp;
                                                </span>
                                                <span>{review.nickname}</span>
                                            </p>
                                            <p className={classes(style.rdDetails, style.rdAuthorLocation)}>
                                                <span>
                                                    <span
                                                        className={style.rdBold}
                                                    >
                                                        From&nbsp;
                                                    </span>
                                                    <span>{review.location}</span>
                                                </span>
                                            </p>
                                            {review.verified_buyer === 1 &&
                                                <Verified />}
                                        </div>
                                    </div>
                                </Section>
                                <MoreDetailsButton
                                    widthValue={review.width}
                                    sizingValue={review.sizing}
                                />
                                <ReviewMedias review={review} />
                                <Footer
                                    className={classes(style.rdFooter, style.rdAuthorLocation)}
                                >
                                    <p className={classes(style.rdBottomline, style.rdInnerContentBlock)}>
                                        <span className={style.rdBold}>
                                            Bottom Line
                                        </span>
                                        <span>
                                            {recommendText(review.recommend)}
                                        </span>
                                    </p>
                                </Footer>
                                <ReviewHelpfulness
                                    key={review.review_id}
                                    review={review}
                                    resetReview={resetReviewAfterVote}
                                    fetchProductReviews={fetchProductReviews}
                                    productId={productId}
                                />
                            </div>
                        );
                    })}
                    {productReviews.length > 0 ? (
                        <Footer className={style.rdMainFooter}>
                            <div className={style.rdContentBlock}>
                                <ScrollToElementBtn
                                    elementId="review-display"
                                    className={classes(
                                        style.rdToTop,
                                        style.frdFlagReviewBtn,
                                        style.underline,
                                    )}
                                    btnText="Back to Top"
                                    ariaLabel="Back to Top"
                                />
                            </div>
                        </Footer>
                    ) : (
                        <Footer className={style.rdMainFooterNoReviews}>
                            <div className={style.rdContentBlock}>
                                <Section
                                    className={classes(
                                        style.reviewDisplay,
                                        style.rdDisplayDesktop,
                                    )}
                                >
                                    <div className={style.rdNoReviews}>
                                        <a
                                            href={`${window.location.href}#write-review`}
                                            className={style.snippetWriteReviewLink}
                                            rel="nofollow"
                                        >
                                            Write the First Review
                                        </a>
                                    </div>
                                </Section>
                            </div>
                        </Footer>
                    )}
                </Section>
            )}
            {isReviews(window) && (
                <span className={style.reviewFormPageLink}>
                    <ReviewForm productId={productId} />
                </span>
            )}
        </>
    );
};

const ConnectedReviewDisplay = injectWindow(connectProductReviews(ReviewDisplayImpl));

export { ConnectedReviewDisplay as ReviewDisplay };
