import React from 'react';
import { ProductDetails } from 'ui/component/product-details';
import { ConnectProduct } from '@silkpwa/module/react-component/connect-product';
import { Breadcrumbs } from 'ui/component/breadcrumbs';
import { MainColumn } from 'ui/component/main-column';
import { DocumentTitle } from '@silkpwa/module/react-component/document-title';
import { SEOTags } from 'ui/component/seo-tags';
import { ProductRichSnippet } from 'ui/component/rich-snippets/product-rich-snippet';
import { ShowLoader } from 'ui/component/show-loader';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ProductReviewRichSnippet } from 'ui/component//rich-snippets/product-review-rich-snippet';
import styles from './style.css';

const ProductPage = () => {
    const t = usePhraseTranslater();
    return (
        <ConnectProduct
            fallback={(
                <div className={styles.loader}>
                    <ShowLoader message={t('Loading product')} />
                </div>
            )}
        >
            {product => (
                <div style={{ backgroundColor: '#ffffff' }}>
                    <DocumentTitle>{product.meta_title}</DocumentTitle>
                    <SEOTags info={product} />
                    <ProductRichSnippet product={product} />
                    <ProductReviewRichSnippet product={product} />
                    <Breadcrumbs path={product.breadcrumbs} />
                    <MainColumn className="maincolumn-productpage">
                        <ProductDetails product={product} />
                    </MainColumn>
                </div>
            )}
        </ConnectProduct>
    );
};

export { ProductPage as default };
