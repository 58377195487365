import React from 'react';
import style from './style.css';

type RatingVote = {
    // eslint-disable-next-line camelcase
    rating_code: string;
    percent: string;
    value: string;
};

type ProductReview = {
    // eslint-disable-next-line camelcase
    rating_votes: RatingVote[];
};
type FilterProps = {
    productReviews: ProductReview[];
    applyFilter: (star: string) => void;
    clearFilter: () => void;
    filter: string | null;
};

const Histogram = ({
    productReviews, applyFilter, clearFilter, filter,
}: FilterProps) => {
    const ratingsCount = {
        5: 0, 4: 0, 3: 0, 2: 0, 1: 0,
    };

    productReviews.forEach((review) => {
        const ratingValue = review.rating_votes[0].value;
        ratingsCount[ratingValue] = (ratingsCount[ratingValue] || 0) + 1;
    });

    const totalReviews = Object.values(ratingsCount).reduce((total, count) => total + count, 0);


    return (
        <section className={style.histogramRoot} aria-labelledby="histogramroot">
            <div className={style.snapshotHistogram}>
                <h1 className={style.histogramHeadline}>Ratings Distribution</h1>
                <ul className={style.histogramList}>
                    {Object.entries(ratingsCount).sort((a, b) => b[0].localeCompare(a[0])).map(([star, count]) => (
                        <li key={star} className={`${style.listItems} ${count === 0 ? style.zeroRatings : ''}`}>
                            <div
                                role="button"
                                tabIndex={count > 0 ? 0 : -1}
                                aria-label={`${count} Reviews ${star} Stars. Show only ${star} star reviews`}
                                className={` ${style.starsCount} ${star} ${count === 0 ? style.noReviews : ''}`}
                                onClick={() => count > 0 && applyFilter(star)}
                                onKeyDown={(e) => {
                                    if ((e.key === 'Enter' || e.key === 'Space') && count > 0) {
                                        e.preventDefault();
                                        applyFilter(star);
                                    }
                                }}
                                aria-disabled={count === 0 ? 'true' : 'false'}
                            >
                                <p className={style.histogramLabel} aria-hidden="true">
                                    {star}
                                    {' '}
                                    Stars
                                </p>
                                <div
                                    data-tooltip={`Show only ${star} star reviews`}
                                    className={` ${style.barContainer} ${count === 0 ? style.noReviews : ''}`}
                                    aria-hidden="true"
                                >
                                    <div className={style.histogramBar}>
                                        <div
                                            className={`${style.barValue} ${filter === star.toString() ? style.barValueSelected : ''}`}
                                            style={{ width: `${(count / totalReviews) * 100}%` }}
                                        />
                                    </div>
                                </div>
                                <p
                                    className={`${style.histogramCount} ${filter === star.toString() ? style.countSelected : ''}`}
                                    aria-hidden="true"
                                >
                                    {count}
                                </p>
                                {filter === star && (
                                    <div
                                        className={style.histogramCross}
                                        aria-hidden="true"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            clearFilter();
                                        }}
                                    >
                                        <span className={style.filterClose}>
                                            <svg viewBox="9.5 9.5 21 21" focusable="false">
                                                <g
                                                    className={style.filterCloseGroup}
                                                >
                                                    <circle
                                                        className={style.closeCircle}
                                                        cx="20"
                                                        cy="20"
                                                        r="10"
                                                        stroke="#333"
                                                        strokeWidth="1"
                                                        fill="none"
                                                    />
                                                    <line
                                                        className={style.closeLine}
                                                        x1="15"
                                                        y1="15"
                                                        x2="25"
                                                        y2="25"
                                                        stroke="#333"
                                                        strokeWidth="2"
                                                    />
                                                    <line
                                                        className={style.closeLine}
                                                        x1="25"
                                                        y1="15"
                                                        x2="15"
                                                        y2="25"
                                                        stroke="#333"
                                                        strokeWidth="2"
                                                    />
                                                </g>
                                            </svg>
                                        </span>
                                    </div>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default Histogram;
